import React, { useEffect, useRef, useState } from 'react'
import axiosClient from '../../axios-client'
import { useStateContext } from '../../context/ContextProvider'
import { Link, Navigate } from 'react-router-dom'
import '../../Auth.css'

const Signup = () => {

    const nameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    const password_confirmationRef = useRef()

    const [errors, setErrors] = useState()
    const [loading, setLoading] = useState(false)
    const {setUser, setToken, setStatus, errorNotify, notification, setErrorNotify, setNotification} = useStateContext()

    useEffect(() => {
        const scrollingElement = (document.scrollingElement || document.body);
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
    }, [])

    const onSubmit = (ev) => {
        ev.preventDefault()

        const payLoad = {
            name : nameRef.current.value,
            email : emailRef.current.value,
            password : passwordRef.current.value,
            password_confirmation : password_confirmationRef.current.value
        }

        setLoading(true)
        axiosClient.post("/signup", payLoad)
        .then((data) => {
            if (data.status == 201) {
                setUser(data.data.user.name)
                setToken(data.data.token)
                setStatus(data.data.status)

                // Navigate to the dashboard
                return <Navigate to="/login"/>
            }
        })
        .catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                    setLoading(false)
                    setErrorNotify("An error occurred, kindly fix it before you can proceed.")
                    // console.log(data);
                if (response.data.errors) {
                        setErrors(response.data.errors)
                        console.log(response.data.errors);
                        
                }else{
                    setErrors({
                        email: [response.data.msg]
                    })
                }
            }else{
                setLoading(false)
                setErrorNotify("Oops!! Some errors occurred.")
            }
        })
    }

  return (
    <div className='py-2 animated fadeInDown'>
        {/* Questions and options */}
        {/* {errors && <div className='bg-red-800 px-1 rounded-md text-white'>
            {Object.keys(errors).map(key => (
                <p key={key}>{errors[key][0]}</p>
            ))}
        </div>
        } */}
      <form onSubmit={onSubmit}>
        <div className='mx-2'>
            <label htmlFor="" className=' mb-1'></label>
            <input type="text" ref={nameRef} className='auth-input' placeholder='Enter your fullname'/>
            {
                errors && 
                <div className='px-1 mt-1 rounded-md text-red-600'>
                    <p>{errors.name}</p>
                </div>
            }
        </div>
        <div className='mx-2 mt-4'>
            <label htmlFor="" className=''></label>
            <input type="text" ref={emailRef} className='auth-input' placeholder='Enter your email'/>
            {
                errors && 
                <div className='px-1 mt-1 rounded-md text-red-600'>
                    <p>{errors.email}</p>
                </div>
            }
        </div>
        <div className='mx-2 mt-4'>
            <label htmlFor="" className=''></label>
            <input type="password" ref={passwordRef} className='auth-input' placeholder='Enter your password'/>
            {
                errors && 
                <div className='px-1 mt-1 rounded-md text-red-600'>
                    <p>{errors.password}</p>
                </div>
            }
        </div>
        <div className='mx-2 mt-4'>
            <label htmlFor="" className=''></label>
            <input type="password" ref={password_confirmationRef} className='auth-input' placeholder='Confirm your password'/>
            {
                errors && 
                <div className='px-1 mt-1 rounded-md text-red-600'>
                    <p>{errors.password_confirmation}</p>
                </div>
            }
        </div>

        <div className='col-md-6 mx-auto mt-4 justify-center'>
            <button className='btn-submit w-100'>
                {
                    loading
                        ?
                    <div className="spinner-border fw-lighter text-purple-800 " role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                        :
                    <b>Submit</b>
                }
            </button>
            <p className='text-center text-border'>
                Already have an account? {""}
                <Link to="/signin" className='text-dryGray font-semibold mt-3 no-underline'>Sign In</Link>
            </p>
        </div>
      </form>


      {notification &&
            <div className='notification animated slideInLeft bg-purple-900 mt-6 -right-20 -bottom-50'>
                {notification}
                <div className='close'>x</div>
            </div>
        }

        {
            errorNotify
                &&
            <div className='notification animated slideInLeft mt-6 -right-20 -bottom-50'>
                <span className='text-red-700'>{errorNotify}</span>
                <div className='close'>x</div>
            </div>
        }
    </div>
  )
}

export default Signup;