import React, { useEffect, useState } from 'react'
import { Link, Navigate, Outlet, useParams } from 'react-router-dom';
import "../nav.css"
import UploadModal from './UploadModal';
import { BsUpload } from 'react-icons/bs';
import logo from "../assets/logos/ccodel.png"
import { useStateContext } from '../context/ContextProvider';
import axiosClient from '../axios-client';
import { FaSignOutAlt, FaVideo } from 'react-icons/fa';
import { FaFileCircleQuestion } from "react-icons/fa6";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";

const DefaultLayout = () => {

  const [admin, setAdmin] = useState(false)
  const [videoModal, setVideoModal] = useState(false);
  const [questionModal, setQuestionModal] = useState(false);
  const {id} = useParams()
  const {user, token, status, errorNotify, setUser, setToken, setStatus, setErrorNotify} = useStateContext()

  useEffect(() => {
    axiosClient.get("/user")
    .then((data) => {
      setUser(data.data?.name)
      setStatus(data.data?.admin)
    })
    .catch(err => {
      const response = err.response;
          if (response && response.status === 401) {
                setErrorNotify("Login first now, Who is this bush person? lol!")
              setTimeout(() => {
                return () => <Navigate to="/signin"/>
              }, 1000)
          }
      })
  }, [])



  if (!token) {
    return <Navigate to="/signin"/>
  }

    // ;
    const handleQuestionModal = (ev) => {
      ev.preventDefault()
      setQuestionModal(true)
    }

    const handleVideoModal = (ev) => {
      ev.preventDefault()
      setVideoModal(true)
    }


    //Signout function
    const signOut = (ev) => {
      ev.preventDefault();
      axiosClient.get("/signout")
      .then(() => {
        setUser({})
        setToken(null)
      })
      .catch(err => {
        const response = err.response;
              if (response && response.status === 401) {
                    setErrorNotify("You've already been kicked out, my friend don't spoil that button.")
                  setTimeout(() => {
                    return () => <Navigate to="/signin"/>
                  }, 1000)
              }
      })
    }

  return (
    <div>
      <nav>
          <ul className=''>
            <li className=''><Link to="/"><MdKeyboardDoubleArrowLeft  className='text-2xl fw-bold menu'/><img src={logo} alt="CCODeL" className='w-35 h-10' /></Link></li>
              {status == "true" 
                      &&
                <> 
                  <li><Link onClick={handleQuestionModal} className='text-lg fw-bolder menu'><BsUpload />&nbsp; Upload &nbsp; <FaFileCircleQuestion /></Link></li>
                  <li><Link onClick={handleVideoModal} className='text-lg fw-bolder menu'><BsUpload />&nbsp; Upload &nbsp; <FaVideo/> </Link></li>
                </>
              }
            <li><Link onClick={signOut} className='border-t-0 border-b-2 text-red-700 menu'>SignOut&nbsp;<FaSignOutAlt /></Link></li>
          </ul>
      </nav>

        <Outlet/>

        <UploadModal questionModal={questionModal} setQuestionModal={setQuestionModal} videoModal={videoModal} setVideoModal={setVideoModal} videoId={id}/>
    </div>
  )
}


export default DefaultLayout;