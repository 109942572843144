
import { createBrowserRouter } from "react-router-dom";
import DefaultLayout from "./components/DefaultLayout";
import Upload from "./views/users/Upload";
import GuestLayout from "./components/GuestLayout";
import Signin from "./views/auth/Signin";
import Signup from "./views/auth/Signup";
import NotFound from "./views/NotFound";
import React from "react";

import VideoPlayerLoading from "./components/loaders/VideoPlayerLoading";
import VideoPageLoading from "./components/loaders/VideoPageLoading";


const LazyVideoPage = React.lazy(() => import('./views/users/VideosPage'))
const LazyVideoPlayer = React.lazy(() => import('./views/users/VideoPlayer'))

const router = createBrowserRouter([
    {
        path : "/",
        element : <DefaultLayout/>,
        children : [
            {
                path : "/",
                element : <React.Suspense fallback={<VideoPageLoading/>}><LazyVideoPage/></React.Suspense>
            },
            {
                path : "/player/:id",
                element : <React.Suspense fallback={<VideoPlayerLoading/>}><LazyVideoPlayer/></React.Suspense>
            },
            {
                path : "/upload",
                element : <Upload/>
            },
        ]
    },

    {
        path : "/",
        element : <GuestLayout/>,
        children : [
            {
                path : "/signin",
                element : <Signin/>
            },
            {
                path : "/signup",
                element : <Signup/>
            },
        ]
    },

    {
        path : "*",
        element : <NotFound/>
    }
])

export default router;