import React, { useRef } from 'react'
import { useState } from 'react'
import axiosClient from '../../axios-client'
import { useStateContext } from '../../context/ContextProvider'

const UploadQuestions = ({videoId}) => {

    const questionRef = useRef()
    const answerRef = useRef()
    const optionOneRef = useRef()
    const optionTwoRef = useRef()
    const optionThreeRef = useRef()
    const optionFourRef = useRef()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState()
    const {notification, setNotification} = useStateContext()


    const onSubmit = (ev) => {
        ev.preventDefault()

        const video_id = videoId;
        const question = questionRef.current.value;
        const answer = answerRef.current.value;
        const option = `${optionOneRef.current.value};${optionTwoRef.current.value};${optionThreeRef.current.value};${optionFourRef.current.value}`;

        const payLoad = {
            video_id : video_id,
            question : question,
            answer : answer,
            options : option
        }
        if (video_id == "" || question == "" || answer == "" || option == "") {
            
        }
        setLoading(true)
        axiosClient.post("questions/", payLoad)
        .then((data) => {
            if (data.status == 201) {
                setTimeout(() => {
                    setLoading(false)
                    setNotification("Question uploaded successfully!")
                }, 2000)
            }
        })
        .catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
            setLoading(false)
                if (response.data.errors) {
                        setErrors(response.data.errors)
                }else{
                    setErrors({
                        email: [response.data.msg]
                    })
                }
            }
          })

    }

  return (
    <div>
        <div className="card card-body">
            <div className="form-group py-2">
                <div className="questions">
                    {/* Questions and options */}
                    {errors && <div className='bg-red-800 px-2 rounded-md text-white'>
                        {Object.keys(errors).map(key => (
                            <p key={key}>{errors[key][0]}</p>
                        ))}
                    </div>
                    }
                    <form onSubmit={onSubmit}>
                        <div className='col-md-12'>
                            <div className="question mb-2">
                                <textarea type="text" ref={questionRef} className="form-control pt-2 w-100 h-30" placeholder="Input Question"/>
                            </div>

                            {/* Options */}
                            <div className="col-md-11 mx-auto options">
                                <div className='row mb-2'>
                                    <textarea type="text" ref={optionOneRef} className="form-control w-50 h-13" placeholder="Answer Option One"/>
                                    <textarea type="text" ref={optionTwoRef} className="form-control w-50 h-13" placeholder="Answer Option Two"/>
                                </div>
                                <div className='row'>
                                    <textarea type="text" ref={optionThreeRef} className="form-control w-50 h-13" placeholder="Answer Option Three"/>
                                    <textarea type="text" ref={optionFourRef} className="form-control w-50 h-13" placeholder="Answer Option Four"/>
                                </div>
                            </div>
                            <div className='pt-3'>
                                <textarea type="text" ref={answerRef} className="form-control w-100 h-15" placeholder="Input the right answer to the question"/>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <button type='submit' className='btn btn-success w-100'>
                                {
                                    loading
                                        ?
                                    <div className="spinner-border fw-lighter text-purple-900 text-sm w-2 h-2" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                        :
                                    <div className='fw-bold text-lg'>Submit</div>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default UploadQuestions;