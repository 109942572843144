import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import axiosClient from '../../axios-client'
import { useStateContext } from '../../context/ContextProvider'

 const Signin = () => {
    const emailRef = useRef()
    const passwordRef = useRef()
    const [errors, setErrors] = useState()
    const [loading, setLoading] = useState(false)
    const {setUser, setToken, setStatus, errorNotify, notification, setErrorNotify} = useStateContext()

    useEffect(() => {
        const scrollingElement = (document.scrollingElement || document.body);
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
    }, [])

    const onSubmit = (ev) => {
        ev.preventDefault();

        const email = emailRef.current.value;
        const password = passwordRef.current.value;

        const payLoad = {
            email : email,
            password : password
        }
        setLoading(true)
        axiosClient.post("/signin", payLoad)
        .then((data) => {
            setUser(data.data?.user)
            setToken(data.data?.token)
            setStatus(data.data?.status)
            localStorage.setItem(`USER_${data.data?.user.id}`, data.data?.user.id)
            setLoading(false)
        })
        .catch(err => {
            const response = err.response;
            if (response && response.status === 422) {
                setLoading(false)
                setErrorNotify("Some errors occurred, kindly sort them out before you can proceed.")
                if (response.data.errors) {
                        setErrors(response.data.errors)
                        console.log(response.data.errors);
                        
                }else{
                    setErrors({
                        email: [response.data.msg]
                    })
                }
            }else{
                setLoading(false)
                setErrorNotify("Oops!! Some errors occurred.")
            }
        })
    }


  return (
    <div className='py-3 animated fadeInDown body'>
      <form onSubmit={onSubmit} >
        <div className='mx-2'>
            <label htmlFor=""></label>
            <input type="email" ref={emailRef} className='auth-input' placeholder='Email'/>
            {
                errors && 
                <div className='px-1 mt-1 rounded-md text-red-600'>
                    <p>{errors.email}</p>
                </div>
            }
        </div >
        <div className='mx-2 mt-4'>
            <label htmlFor=""></label>
            <input type="password" ref={passwordRef} className='auth-input' placeholder='Password'/>
            {
                errors && 
                <div className='px-1 mt-1 rounded-md text-red-600'>
                    <p>{errors.password}</p>
                </div>
            }
        </div>
        <div className='col-md-8 mt-4 mx-auto'>
            <button type='submit' className='btn-submit w-100'>
                {
                    loading
                        ?
                    <div className="spinner-border fw-lighter text-purple-800 " role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                        :
                    <b>Submit</b>
                }
            </button>
            <p className='text-center text-border mt-3'>
                Yet to have an account?{""}
                <Link to="/signup" className='text-dryGray font-semibold ml-2 no-underline'>Sign Up</Link>
            </p>
        </div>
      </form>

        {
            errorNotify
                &&
            <div className='notification animated slideInLeft mt-6 -right-20 bottom-35'>
                <span className='text-red-700'>{errorNotify}</span>
                <div className='close'>x</div>
            </div>
        }
    </div>
  )
}


export default Signin;