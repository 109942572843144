import React, { Fragment, useRef, useState } from 'react';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { useNavigate } from 'react-router-dom';
import UploadQuestions from '../views/users/UploadQuestions';
import UploadVideos from '../views/users/UploadVideos';


function UploadModal({videoModal, questionModal, setVideoModal, setQuestionModal, videoId}){
    const cancelButtonRef = useRef()
    const navigate = useNavigate

    const closeVideoModal = () => {
        setVideoModal(false)
      }

    const closeQuestionModal = () => {
        setQuestionModal(false)
      }

    
return (
  <div>
      <Transition appear show={videoModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeVideoModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center min-w-full">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <DialogTitle
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <div className="card card-header">
                        <h5 className="card-title fw-bold text-center">
                          Upload Questions 
                        </h5>
                    </div>
                  </DialogTitle>

                  <div className="mt-2">

                    <UploadVideos/>

                    <div className='w-100'>
                        {/* <VideoPlayer/> */}
                    </div>
                  </div>

                  <div className="mt-4 flex justify-between">
                    {/* <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-blue-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={handleUpload}
                    >
                        Upload
                    </button> */}
                    <button
                      type="button"
                      className="inline-flex justify-end rounded-md border border-transparent bg-red-900 px-4 py-2 text-sm font-medium text-white hover:bg-red-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeVideoModal}
                    >
                      Cancel
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>


      <Transition appear show={questionModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeQuestionModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center min-w-full">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <DialogTitle
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <div className="card card-header">
                        <h5 className="card-title fw-bold text-center">
                          Upload Videos 
                        </h5>
                    </div>
                  </DialogTitle>

                  <div className="mt-2">

                    <UploadQuestions videoId={videoId}/>

                    <div className='w-100'>
                        {/* <VideoPlayer/> */}
                    </div>
                  </div>

                  <div className="mt-4 flex justify-between">
                    {/* <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-blue-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={handleUpload}
                    >
                        Upload
                    </button> */}
                    <button
                      type="button"
                      className="inline-flex justify-end rounded-md border border-transparent bg-red-900 px-4 py-2 text-sm font-medium text-white hover:bg-red-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeQuestionModal}
                    >
                      Cancel
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
</div>
)
}

export default UploadModal