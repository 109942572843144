import React from 'react'
import { CiImageOn } from 'react-icons/ci'

function VideoPageLoading() {
  return (
    <div className='container'>
      <div className='col-md-12 p-2'>
        <div className='row'>

          <div className='col-md-3 mx-auto mb-3 dummy-vid-page'>
            <div className='img-thumbnail'>
              <div>
                <CiImageOn  className='col-md-12 mt-3 img-thumbnail h-100 text-gray-200 dark:text-gray-600 animate-pulse'/>
              </div>
              <div className='card text-center mt-3'>
                <i className='text-decoration-none animate-pulse'></i>
              </div>
            </div>
          </div>
          
          <div className='col-md-3 mx-auto mb-3 dummy-vid-page'>
            <div className='img-thumbnail'>
              <div>
                <CiImageOn  className='col-md-12 mt-3 img-thumbnail h-100 text-gray-200 dark:text-gray-600 animate-pulse'/>
              </div>
              <div className='card text-center mt-3'>
                <i className='text-decoration-none animate-pulse'></i>
              </div>
            </div>
          </div>

          <div className='col-md-3 mx-auto mb-3 dummy-vid-page'>
            <div className='img-thumbnail'>
              <div>
                <CiImageOn  className='col-md-12 mt-3 img-thumbnail h-100 text-gray-200 dark:text-gray-600 animate-pulse'/>
              </div>
              <div className='card text-center mt-3'>
                <i className='text-decoration-none animate-pulse'></i>
              </div>
            </div>
          </div>

          <div className='col-md-3 mx-auto mb-3 dummy-vid-page'>
            <div className='img-thumbnail'>
              <div>
                <CiImageOn  className='col-md-12 mt-3 img-thumbnail h-100 text-gray-200 dark:text-gray-600 animate-pulse'/>
              </div>
              <div className='card text-center mt-3'>
                <i className='text-decoration-none animate-pulse h-5'></i>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default VideoPageLoading
