import React from 'react'
import { CiImageOn } from "react-icons/ci";
import '../../App.css'
import { FaPhotoVideo } from 'react-icons/fa';

function VideoPlayerLoading() {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-7 position-relative fadeInDown bunny'>
            <div className='card card-header text-center fw-bold h-40'></div>
                <FaPhotoVideo className='col-md-12 mt-3 img-thumbnail h-100 text-gray-200 dark:text-gray-600 animate-pulse'/>
            <div className='col-md-12 my-3 card h-52 btn-disable animate-pulse'>
                <div className='py-3'></div>
            </div>
        </div>
        
        <div className='col-md-5'>
            <div className='col-md-12 ml-5 available-question animate-pulse'>
                <div className='card card-header mb-3 h-40'>

                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default VideoPlayerLoading
