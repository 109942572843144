import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider'

export default function GuestLayout() {
    const {user, token} = useStateContext()

    if (token) {
        return <Navigate to="/"/>
    }
  return (
    <div className=''>
        <div className='col-md-12 mx-auto'>
            <div className='row'>
                <div className='col-md-5 bg-slate-200 h-screen'>
                    <div className='absolute h-40 w-30 m-auto top-0 bottom-0 left-20'>
                        <div className='text-center shadow'>
                            <span className='fw-bold'>WELCOME</span>
                            <img className='' src={require("../assets/logos/ccodel.png")} alt="" />
                        </div>
                    </div>
                </div>
                <div className='col-md-6 m-auto'>
                    <div className='container img-thumbnail'>
                        <Outlet/>
                    </div>   
                </div>
            </div>
        </div>
    </div>
  )
}
