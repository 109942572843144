import React, { useRef, useState } from 'react'
import { useStateContext } from '../../context/ContextProvider'

function UploadVideos() {
  const nameRef = useRef()
  const linkRef = useRef()
  const posterRef = useRef()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState()
  const {notification, setNotification} = useStateContext()

  const onSubmit = (ev) => {
      ev.preventDefault();
  }

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className='img-thumbnail m-2 p-3'>
          <div className='py-2'>
            <label htmlFor="">Title</label>
            <input type="text" ref={nameRef} className='form-control' placeholder='Video title'/>
          </div>
          <div className='mb-2'>
            <label htmlFor="">Link</label>
            <input type="text" ref={linkRef} className='form-control' placeholder='Video link'/>
          </div>
          <div className='mb-2'>
            <label htmlFor="">Poster</label>
            <input type="text" ref={posterRef} className='form-control' placeholder='Video cover photo (poster)'/>
          </div>

          <div className='mt-4'>
            <button type='submit' className='btn btn-success w-100'>
                  {
                      loading
                          ?
                      <div className="spinner-border fw-lighter text-purple-900 text-sm w-2 h-2" role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                          :
                      <div className='fw-bold text-lg'>Submit</div>
                  }
              </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default UploadVideos
